export default {
    hostEnv: process.env.REACT_APP_ENV,
    baseAPI: process.env.REACT_APP_BASE_API,
    cqIAMBaseAPI: process.env.REACT_APP_CQ_IAM_URL,
    cqAccountsPdBaseAPI: process.env.REACT_APP_CQ_ACCOUNTS_PD_URL,
    cqAccountsCcBaseAPI: process.env.REACT_APP_CQ_ACCOUNTS_CC_URL,
    cqAccountsCommonBaseAPI: process.env.REACT_APP_CQ_ACCOUNTS_COMMON_URL,
    licenseServiceBaseAPI: process.env.REACT_APP_LICENSE_SERVICE_URL,
    loginUrl: process.env.REACT_APP_LOGIN_URL,
    disableSSL: process.env.REACT_APP_DISABLE_SSL,
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    translationURL: process.env.REACT_APP_TRANSLATION_URL,
    privacyUrl: process.env.REACT_APP_PRIVACY_URL,
    skewedAnalyticsReports: process.env.REACT_APP_SKEWED_ANALYTICS_REPORTS_API,
    skewedAnalyticsScheduledReports: process.env.REACT_APP_SKEWED_ANALYTICS_SCHEDULED_REPORTS_API,
    skewedAnalyticsOnDemandReports: process.env.REACT_APP_SKEWED_ANALYTICS_ON_DEMAND_API,
    skewedAnalyticsReportsEU: process.env.REACT_APP_SKEWED_ANALYTICS_REPORTS_EU_API,
    skewedAnalyticsScheduledReportsEU: process.env.REACT_APP_SKEWED_ANALYTICS_SCHEDULED_REPORTS_EU_API,
    skewedAnalyticsOnDemandReportsEU: process.env.REACT_APP_SKEWED_ANALYTICS_ON_DEMAND_EU_API,
    paradomeBaseUrl: process.env.REACT_APP_PARADOME_URL,
    outbrainNetworkId: process.env.REACT_APP_OUTBRAIN_NETWORKID,
    outbrainDashboardId: process.env.REACT_APP_OUTBRAIN_DASHBOARDID,
    outbrainSuperSetUrl: process.env.REACT_APP_SUPERSET_DOMAIN,
    hubspotUrl: process.env.REACT_APP_HUBSPOT_URL,
    dellNetworkId: process.env.REACT_APP_DELL_NETWORK_ID,
    logRocketEnv: process.env.REACT_APP_LOGROCKET_ENV,
    marketoTokenizedPayloadSecret: process.env.REACT_APP_MARKETO_TOKENIZED_PAYLOAD_SECRET,
    marketoBaseUrl: process.env.REACT_APP_MARKETO_BASE_API,
};
