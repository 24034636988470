import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useEffect, useState, useRef } from 'react';
import SupersetService from '../../../services/api/superset.service';
import Header from '../../containers/header';
import styles from './outbrain.module.scss';
import SnackbarContainer from '../main/SnackbarContainer';
import config from '@config';

export default function Outbrain() {
    useEffect(() => {
        (async () => {
            const tokenRes = await SupersetService.getGuestToken();
            if (tokenRes && tokenRes.token) {
                embedDashboard({
                    id: config.outbrainDashboardId, // given by the Superset embedding UI
                    supersetDomain: config.outbrainSuperSetUrl,
                    mountPoint: document.getElementById('outbrain-dashboard'), // html element in which iframe render
                    fetchGuestToken: () => tokenRes.token,
                    dashboardUiConfig: { hideTitle: true },
                });
            }
        })();
    }, []);

    return (
        <>
            <div className={styles.container}>
                <Header title={'Outbrain'} />
                <div className={styles.root}>
                    <div id={'outbrain-dashboard'} className={styles.dashboard}></div>
                </div>
                <SnackbarContainer />
            </div>
        </>
    );
}
