import { Client } from '@hubspot/api-client';
import * as hubspot from '@hubspot/api-client';
import { LEAD_PROTECTION } from 'utils/common';

const DEVELOPER_API_KEY = process.env.REACT_APP_HUBSPOT_DEVELOPER_API_KEY;
const hubspotClient: Client = new hubspot.Client({ developerApiKey: DEVELOPER_API_KEY });
export const redirectUri = `${process.env.REACT_APP_REDIRECT_URI}/${LEAD_PROTECTION}/settings`;

const generateAuthorizationUrl = () => {
    const clientId: any = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
    const scope: any = process.env.REACT_APP_HUBSPOT_SCOPE;
    const validScope: string = scope.replaceAll('_', ' ');
    const uri = hubspotClient.oauth.getAuthorizationUrl(clientId, redirectUri, validScope);

    return uri;
};

export const redirectToHubSpotAuthorization = () => {
    const authorizationUrl = generateAuthorizationUrl();
    window.location.href = authorizationUrl;
};
