import { httpRequest, methods } from '../http';

type TokenResponse = {
    token: string;
};
const BASE_URL = 'api/v2/superset';

class SupersetService {
    static getGuestToken = async (): Promise<TokenResponse> => {
        const responsePromise = httpRequest({
            pathname: `${BASE_URL}/token`,
            method: methods.get,
        });
        const response: Promise<TokenResponse> = responsePromise.response;
        return response;
    };
}

export default SupersetService;
