import { httpRequest, methods } from '../http';

export const paidMarketingKeys = {
    INVALID_VISITS_BY_COUNTRY: 'invalid_visits_by_country',
    INVALID_VISITS_BY_URL: 'invalid_visits_by_url',
    INVALID_VISITS_BY_REASON: 'invalid_visits_by_reason',
    TRENDS: 'trends',
    TOP_PLATFORMS_BY_TOTAL_VISITS: 'top_platforms_by_total_visits',
    INVALID_VISITS_BY_CAMPAIGN: 'invalid_visits_by_campaing',
    INVALID_VISITS_BY_KEYWORD: 'invalid_visits_by_keyword',
    POTENTIAL_REPLACED_VISITS: 'potential_replaced_visits',
    CALCULATED_REPLACED_VISITS: 'calculated_replaced_visits',
    BLOCKED_ENTITIES: 'blocked_entities',
    BLOCKED_PIXELS: 'blocked_pixels',
    PAGE_VIEWS: 'PAGE_VIEWS'
};

const formatGoogleShopping = (body) => {
    if (body.platform === 'GOOGLE SMART SHOPPING') {
        body.platform = 'Google Smart Shopping'
    }
    if (body.platforms?.includes('GOOGLE SMART SHOPPING')) {
        body.platforms.push('Google Smart Shopping')
    }
    if (body.platform === 'GOOGLE SHOPPING') {
        body.platform = 'Google Shopping'
    }
    if (body.platforms?.includes('GOOGLE SHOPPING')) {
        body.platforms.push('Google Shopping')
    }
}
export const getInvalidVisitsByCountries = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_invalid_visits_by_country', body });
    return response;
};

export const getInvalidVisitsByUrl = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_invalid_visits_by_url', body });
    return response;
};

export const getInvalidVisitsByCampaigns = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_invalid_visits_by_campaign', body });
    return response;
};

export const getInvalidVisitsByKeywords = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_invalid_visits_by_keyword', body });
    return response;
};

export const getInvalidVisitsByReason = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_invalid_visits_by_reason', body });
    return response;
};

export const getTrends = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_trends', body });
    return response;
};

export const getTopPlatformsByTotalVisits = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_top_platforms_by_total_visits', body });
    return response;
};

export const getPotentialReplacedVisitsComponent = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_potential_replaced_visits', body });
    return response;
};

export const getCalculatedReplacedVisitsComponent = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_calculated_replaced_visits', body });
    return response;
};

export const getBlockedEntitiesComponent = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_blocked_entities', body });
    return response;
};

export const getBlockedPixels = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_blocked_pixels', body });
    return response;
}

export const getPageViews = async body => {
    formatGoogleShopping(body)
    const { response } = httpRequest({ method: methods.post, pathname: 'dashboard/get_pageviews', body });
    return response;
}