import styles from './adobe-analytics.module.scss';
import Select from '../../../containers/exportables/ui/filters-list/components/select';
import { useContentManager, useSplit } from '../../../../hooks';
import { useMemo, useState } from 'react';
import { AdobeAnalyticsUpdateModel, ErrorsState, InitialState, SelectItem, TagsWithAdobeConfig } from './types';
import { CircularProgress } from '@material-ui/core';
import Button from 'components/widgets/button';
import Toast, { toastTypes } from '../../../../services/notifications';
import CheqUI from '@combotag/cheq-ui';
import { useTagsWithAdobeConfig } from './hooks';
import { useUpdateAdobeAnalyticsIntegrations } from './hooks/use-update-adobe-analytics-integrations';
import Checkbox from 'components/containers/exportables/ui/checkbox';
import ToolTip from 'widgets/tool-tip';
import ExclamationIcon from '../../../svgs/exclamation';
import { InputWithTooltip } from 'widgets/input-with-tooltip';
import { adobeAnalyticsFormSchema, ERRORS } from './schemas';
import { getObjectKeys } from 'utils/common';
import { ZodError } from 'zod';
import { constants } from 'utils/split.io';
import { ADOBE_OBJECT_PROPERTY_NAME, DEFAULT_ADOBE_OBJECT_NAME } from './constants';

const getInitialState = ({ tags, config }: TagsWithAdobeConfig): InitialState => {
    if (config && typeof config !== 'boolean' && typeof config !== 'number') {
        if (!config[ADOBE_OBJECT_PROPERTY_NAME]) {
            config[ADOBE_OBJECT_PROPERTY_NAME] = DEFAULT_ADOBE_OBJECT_NAME;
        }
        return { ...config, tags };
    } else return { tags, adobeObject: DEFAULT_ADOBE_OBJECT_NAME };
};

export const AdobeAnalytics = () => {
    const cm = useContentManager();
    const { useActions } = CheqUI.React.Meiosis;
    const {
        clickTrueTags: { getAll: getTags },
    } = useActions();

    const { tagsWithAdobeConfig, isLoading: isTagsLoading } = useTagsWithAdobeConfig();

    const { mutateAsync: updateAdobeAnalyticsIntegrations, isLoading: isUpdating } = useUpdateAdobeAnalyticsIntegrations();
    const [initialState, setInitialInputs] = useState<InitialState>(getInitialState(tagsWithAdobeConfig));
    const [currentState, setCurrentInputs] = useState<InitialState>(getInitialState(tagsWithAdobeConfig));
    const [errors, setErrors] = useState<Partial<ErrorsState>>();
    const isLoading = isTagsLoading || isUpdating;
    const disabled = !currentState.tags?.length;
    const formUpdated = useMemo(() => JSON.stringify(currentState) !== JSON.stringify(initialState), [currentState, initialState]);
    const selected = useMemo(() => currentState.tags?.filter(item => item.selected), [currentState.tags]);
    const { isOn: isV2Settings } = useSplit(constants.PARADOME_ADOBE_ANALYTICS_V2);

    const updateIntegration = async () => {
        const { tags, ...configuration } = currentState;
        const adobeAnalyticsUpdateModel = tags?.reduce<AdobeAnalyticsUpdateModel>(
            (accumulator, current) => {
                accumulator.tags.push({ id: current.value, value: !!current.selected });
                return accumulator;
            },
            { tags: [], configuration }
        );

        if (adobeAnalyticsUpdateModel?.tags.length) {
            try {
                await updateAdobeAnalyticsIntegrations(adobeAnalyticsUpdateModel);
                Toast({ message: cm.get('AdobeAnalytics.success'), type: toastTypes.success, promise: null });
                getTags();
                //Currently, the assumption is that all tags share the same adobe config so, if non selected we discard the config - will be changed in the future
                if (!selected?.length) {
                    const updatedState = { tags };
                    setCurrentInputs(updatedState);
                    setInitialInputs(updatedState);
                } else setInitialInputs(currentState);
            } catch (error) {
                Toast({ message: cm.get('AdobeAnalytics.failed'), type: toastTypes.error, promise: null });
            }
        }
    };

    const getErrorTranslation = (err: string): string => cm.get(`errors.${err}`);

    const parseFormattedZodErrors = (err: ZodError<ErrorsState>): ErrorsState => {
        const { tags, adobeObject, threatGroupId, threatTypeId, formError: { _errors: formErrors } = { _errors: [] } } = err.format();
        const result: ErrorsState = {
            tags: tags?._errors && getErrorTranslation(tags?._errors[0]),
        };
        const translatedFormErrors = formErrors.map(err => getErrorTranslation(err));

        if (adobeObject?._errors) {
            if (adobeObject?._errors[0] !== ERRORS.DEFINED) {
                result.adobeObject = getErrorTranslation(adobeObject?._errors[0]);
                translatedFormErrors.push(getErrorTranslation(ERRORS.ADOBE_OBJECT_NAME_REQUIRED));
            }
            if (threatGroupId && threatTypeId && adobeObject?._errors.find(err => err === ERRORS.DEFINED)) translatedFormErrors.push(getErrorTranslation(ERRORS.GROUP_OR_TYPE));
            if (threatGroupId?._errors?.some(err => err === ERRORS.NO_EQUAL_IDS) || threatTypeId?._errors?.some(err => err === ERRORS.NO_EQUAL_IDS)) {
                translatedFormErrors.push(getErrorTranslation(ERRORS.NO_EQUAL_IDS));
            }
        }
        result.formError = translatedFormErrors.join(' ,');

        const threatGroupIdError = threatGroupId?._errors?.find(err => err !== ERRORS.DEFINED);
        if (threatGroupIdError) result.threatGroupId = getErrorTranslation(threatGroupIdError);

        const threatTypeIdError = threatTypeId?._errors?.find(err => err !== ERRORS.DEFINED);
        if (threatTypeIdError) result.threatTypeId = getErrorTranslation(threatTypeIdError);

        return result;
    };

    const validateAndUpdate = async () => {
        const stateToParse = isV2Settings ? currentState : { tags: currentState.tags };
        const { error } = await adobeAnalyticsFormSchema.safeParseAsync(stateToParse);
        if (error) {
            const reducedErrors = parseFormattedZodErrors(error);
            setErrors(reducedErrors);
        } else await updateIntegration();
    };

    const setInputs = (inputs: Partial<InitialState>) => {
        setCurrentInputs(currentInputs => ({ ...currentInputs, ...inputs }));
        setErrors({});
    };

    const setNumericInput = (inputs: Pick<ErrorsState, 'threatGroupId' | 'threatTypeId'>) => {
        const reducedInputs = getObjectKeys(inputs).reduce((accumulator, current) => {
            const id = Number(inputs[current]);
            if (!isNaN(Number(id))) accumulator[current] = id;
            else if (inputs[current] === undefined) accumulator[current] = undefined;
            return accumulator;
        }, {});
        setCurrentInputs(currentInputs => ({ ...currentInputs, ...reducedInputs }));
        setErrors({});
    };

    const onSelect: (item: SelectItem, type: string, checked: boolean) => void = (item, _, checked) => {
        const tagsCopy = currentState.tags?.map(tag => ({ ...tag }));
        const option = tagsCopy?.find(option => option.value === item.value);
        if (option) {
            option.selected = checked;
            setInputs({ tags: tagsCopy });
        }
    };

    const threatsTitle = (threat: 'Type' | 'Group') => `CHEQ ${cm.get(`Threat${threat}`)} eVar`;

    const onInputFocus = () => setErrors({});

    return (
        <div className={styles.root}>
            <header>
                <div className={styles.title}>{cm.get('AdobeAnalytics.header')}</div>
                {isV2Settings && <div className={styles.secondaryTitle}>{cm.get('AdobeAnalytics.secondaryHeader')}</div>}
            </header>
            <div className={styles.content}>
                <section className={styles.selectWrapper}>
                    <label>{cm.get('AdobeAnalytics.select.title')}</label>
                    <Select
                        cm={cm}
                        selector={`adobe-analytics-tags`}
                        options={currentState.tags}
                        selected={selected}
                        onSelect={onSelect}
                        placeholder={cm.get('AdobeAnalytics.select.placeholder')}
                        dropDownStyle={{ top: '3.8rem', background: '#282A3F', border: '0.5px solid #FFFFFF' }}
                        disabled={disabled || isLoading}
                        translateLabels={false}
                        type={undefined}
                        style={{
                            height: '2.75rem',
                        }}
                        onFocus={onInputFocus}
                        error={!!errors?.tags}
                    />
                    <label className={styles.error}>{errors?.tags}</label>
                </section>
                {isV2Settings && (
                    <>
                        <section className={styles.inputsWrapper}>
                            <InputWithTooltip
                                label={threatsTitle('Type')}
                                tooltipContent={cm.get('AdobeAnalytics.inputs.threatType.tooltip')}
                                placeholder="0"
                                value={currentState.threatTypeId?.toString()}
                                onChange={(threatTypeId: string) => {
                                    setNumericInput({ threatTypeId: threatTypeId || undefined });
                                }}
                                disabled={isLoading}
                                className={errors?.threatTypeId && styles.inputError}
                            />
                            <InputWithTooltip
                                label={threatsTitle('Group')}
                                tooltipContent={cm.get('AdobeAnalytics.inputs.threatGroup.tooltip')}
                                placeholder="0"
                                value={currentState.threatGroupId?.toString()}
                                onChange={(threatGroupId: string) => {
                                    setNumericInput({ threatGroupId: threatGroupId || undefined });
                                }}
                                disabled={isLoading}
                                className={errors?.threatGroupId && styles.inputError}
                            />
                            <InputWithTooltip
                                label={cm.get('AdobeAnalytics.inputs.tag.title')}
                                tooltipContent={cm.get('AdobeAnalytics.inputs.tag.tooltip')}
                                value={currentState.adobeObject}
                                onChange={(tagName: string) => setInputs({ adobeObject: tagName || undefined })}
                                disabled={isLoading}
                                className={errors?.adobeObject && styles.inputError}
                            />
                        </section>
                        <section className={styles.checkboxWrapper}>
                            <Checkbox
                                selector="AdobeAnalytics.checkbox"
                                checked={!!currentState.triggerAdobeEvent}
                                className={styles.checkbox}
                                label={cm.get('AdobeAnalytics.checkbox.title')}
                                onChange={() => setInputs({ triggerAdobeEvent: !currentState.triggerAdobeEvent })}
                                disabled={disabled || isLoading}
                            />
                            <ToolTip content={cm.get('AdobeAnalytics.checkbox.tooltip')}>
                                <div className={styles.exclamationIcon}>
                                    <ExclamationIcon />
                                </div>
                            </ToolTip>
                        </section>
                    </>
                )}
                <footer>
                    <section className={styles.error}>
                        {errors?.formError && (
                            <>
                                <ExclamationIcon />
                                {errors.formError}
                            </>
                        )}
                    </section>
                    <section className={styles.save}>
                        {isLoading && <CircularProgress size={14} color="inherit" />}
                        <Button
                            isLoading={isLoading}
                            disabled={disabled || isLoading || !formUpdated}
                            className=""
                            onEnter=""
                            style={{ height: '2.5rem' }}
                            onClick={validateAndUpdate}
                        >
                            {cm.get('Save')}
                        </Button>
                    </section>
                </footer>
            </div>
        </div>
    );
};
