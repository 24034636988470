import { capitalizeString } from '@/utils/common';
import { VISIT_TYPES } from '../../constants';

const { total, paid, organic, direct, unclassified } = VISIT_TYPES;

const menuOption = [
    { label: capitalizeString(total), value: total },
    { label: capitalizeString(paid), value: paid },
    { label: capitalizeString(organic), value: organic },
    { label: capitalizeString(direct), value: direct },
    { label: capitalizeString(unclassified), value: unclassified },
];

export { menuOption, VISIT_TYPES };
