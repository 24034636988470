import { isNullish } from 'utils/common';
import { z } from 'zod';
import { ERROR_STATE_KEYS } from './constants';

export const ERRORS = {
    NO_EQUAL_IDS: 'noEqualIds',
    NON_EMPTY: 'notEmpty',
    REQUIRED: 'required',
    NON_ZERO: 'nonZero',
    ADOBE_OBJECT_NAME_REQUIRED: 'adobeObjectRequired',
    GROUP_OR_TYPE: 'groupIdOrTypeId',
    DEFINED: 'defined',
};

export const numericSelectItemSchema = z.object({
    label: z.string(),
    value: z.number(),
    selected: z.boolean().optional(),
});

const AdobeProtectionConfigBaseSchema = z.object({
    formError: z.never().optional(), //Only for error display
});

const adobeProtectionConfigTagsSchema = numericSelectItemSchema.array().min(1, ERRORS.REQUIRED);

const adobeProtectionConfigOptionalTagsBaseSchema = AdobeProtectionConfigBaseSchema.extend({ tags: adobeProtectionConfigTagsSchema });

const adobeProtectionConfigSelectedTagsSchema = adobeProtectionConfigTagsSchema.refine(tags => tags.some(tag => tag.selected), { message: ERRORS.REQUIRED });

const adobeProtectionConfigMandatoryTagsBaseSchema = AdobeProtectionConfigBaseSchema.extend({ tags: adobeProtectionConfigSelectedTagsSchema });

const nullishSchema = z.union([z.undefined({ invalid_type_error: ERRORS.DEFINED }), z.null({ invalid_type_error: ERRORS.DEFINED })]);

const adobeEVarSchema = z.number({ required_error: ERRORS.REQUIRED }).min(1, ERRORS.NON_ZERO);

const adobeObjectNameSchema = z.string({ required_error: ERRORS.REQUIRED, invalid_type_error: ERRORS.REQUIRED }).min(1, ERRORS.NON_EMPTY);

const adobeAnalyticsConfigBaseSchema = z.object({
    adobeObject: adobeObjectNameSchema,
    triggerAdobeEvent: z.boolean().nullish(),
});

const adobeAnalyticsConfigNullishThreatGroupSchema = adobeAnalyticsConfigBaseSchema.extend({
    threatTypeId: adobeEVarSchema,
    threatGroupId: adobeEVarSchema.nullish(),
});

const adobeAnalyticsConfigNullishThreatTypeSchema = adobeAnalyticsConfigBaseSchema.extend({
    threatTypeId: adobeEVarSchema.nullish(),
    threatGroupId: adobeEVarSchema,
});

export const adobeAnalyticsConfigNullishSchema = z.object({
    threatTypeId: nullishSchema,
    threatGroupId: nullishSchema,
    adobeObject: nullishSchema,
    triggerAdobeEvent: z.literal<boolean>(false).nullish(),
});

export const adobeAnalyticsNonEmptyConfigSchema = z.union([adobeAnalyticsConfigNullishThreatGroupSchema, adobeAnalyticsConfigNullishThreatTypeSchema]);

export const adobeAnalyticsConfigSchema = z.union([adobeAnalyticsConfigNullishThreatGroupSchema, adobeAnalyticsConfigNullishThreatTypeSchema, adobeAnalyticsConfigNullishSchema]);

export const adobeAnalyticsFormSchema = z.union([
    adobeProtectionConfigMandatoryTagsBaseSchema.extend({
        threatTypeId: nullishSchema,
        threatGroupId: nullishSchema,
        adobeObject: adobeObjectNameSchema,
        triggerAdobeEvent: z.literal<boolean>(false).nullish(),
    }),
    adobeProtectionConfigMandatoryTagsBaseSchema.and(adobeAnalyticsNonEmptyConfigSchema).superRefine(({ threatGroupId, threatTypeId }, ctx) => {
        if (!isNullish(threatGroupId) && !isNullish(threatTypeId) && threatGroupId === threatTypeId)
            [ERROR_STATE_KEYS.THREAT_TYPE, ERROR_STATE_KEYS.THREAT_GROUP, ERROR_STATE_KEYS.FORM].forEach(path =>
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: ERRORS.NO_EQUAL_IDS,
                    path: [path],
                })
            );
    }),
    adobeProtectionConfigOptionalTagsBaseSchema.and(adobeAnalyticsConfigNullishSchema).superRefine((_, ctx) => {
        if (ctx.path.find(item => item === 'adobeObject'))
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [ERROR_STATE_KEYS.FORM],
                message: ERRORS.GROUP_OR_TYPE,
            });
    }),
]);
